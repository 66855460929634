@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /* Your default theme */
  --background: white;
  --foreground: theme('colors.stone.900');
}

:root {
  /* #region  /**=========== Primary Color =========== */
  /* !STARTERCONF Customize these variable, copy and paste from /styles/colors.css for list of colors */
  --tw-clr-primary-50: 255, 235, 238;
  --tw-clr-primary-100: 255, 204, 210;
  --tw-clr-primary-200: 245, 151, 154;
  --tw-clr-primary-300: 238, 110, 114;
  --tw-clr-primary-400: 249, 72, 78;
  --tw-clr-primary-500: 255, 50, 50;
  --tw-clr-primary-600: 240, 37, 50;
  --tw-clr-primary-700: 222, 23, 44;
  --tw-clr-primary-800: 209, 9, 36;
  --tw-clr-primary-900: 195, 0, 22;
}

html.inti {
  /* #region  /**=========== Primary Color =========== */
  /* !STARTERCONF Customize these variable, copy and paste from /styles/colors.css for list of colors */
  --tw-clr-primary-50: 239, 246, 255; /* Very light blue */
  --tw-clr-primary-100: 219, 234, 254; /* Lighter blue */
  --tw-clr-primary-200: 191, 219, 254; /* Light blue */
  --tw-clr-primary-300: 147, 197, 253; /* Soft blue */
  --tw-clr-primary-400: 96, 165, 250; /* Sky blue */
  --tw-clr-primary-500: 24, 101, 215; /* Base blue */
  --tw-clr-primary-600: 29, 78, 165; /* Dark blue */
  --tw-clr-primary-700: 30, 64, 132; /* Deeper blue */
  --tw-clr-primary-800: 8, 75, 157; /* Very deep blue */
  --tw-clr-primary-900: 22, 45, 72;
}

html {
  --clr-primary-50: rgb(var(--tw-clr-primary-50)); /* #ffebee */
  --clr-primary-100: rgb(var(--tw-clr-primary-100)); /* #ffccd2 */
  --clr-primary-200: rgb(var(--tw-clr-primary-200)); /* #f5979a */
  --clr-primary-300: rgb(var(--tw-clr-primary-300)); /* #ee6e72 */
  --clr-primary-400: rgb(var(--tw-clr-primary-400)); /* #f9484e */
  --clr-primary-500: rgb(var(--tw-clr-primary-500)); /* #ff3232 */
  --clr-primary-600: rgb(var(--tw-clr-primary-600)); /* #f02532 */
  --clr-primary-700: rgb(var(--tw-clr-primary-700)); /* #de172c */
  --clr-primary-800: rgb(var(--tw-clr-primary-800)); /* #d10924 */
  --clr-primary-900: rgb(var(--tw-clr-primary-900)); /* #c30016 */
  /* #endregion  /**======== Primary Color =========== */
}

@layer utilities {
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* clears the ‘X’ from Internet Explorer */
  input[type='search']::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
  input[type='search']::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  /* clears the ‘X’ from Chrome */
  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    display: none;
  }
}

@layer base {
  /* #region  /**=========== Typography =========== */
  h1 {
    @apply font-primary text-2xl font-bold md:text-4xl;
  }

  h2 {
    @apply font-primary text-xl font-bold md:text-3xl;
  }

  h3 {
    @apply font-primary text-lg font-bold md:text-2xl;
  }

  h4 {
    @apply font-primary text-base font-bold md:text-lg;
  }

  body {
    @apply font-primary text-sm md:text-base;
  }
  /* #endregion  /**======== Typography =========== */

  p,
  label {
    @apply text-inkanText dark:text-white;
  }

  body {
    /* overflow: hidden; */
  }

  .layout {
    /* 1100px */
    max-width: 68.75rem;
    @apply mx-auto w-11/12;
  }

  .bg-dark a.custom-link {
    @apply border-stone-200 hover:border-stone-200/0;
  }

  /* Class to adjust with sticky footer */
  .min-h-main {
    @apply min-h-[calc(100vh-56px)];
  }
}

@layer utilities {
  .animated-underline {
    background-image: linear-gradient(#33333300, #33333300),
      linear-gradient(to right, var(--clr-primary-400), var(--clr-primary-500));
    background-size: 100% 2px, 0 2px;
    background-position: 100% 100%, 0 100%;
    background-repeat: no-repeat;
  }
  @media (prefers-reduced-motion: no-preference) {
    .animated-underline {
      transition: 0.3s ease;
      transition-property: background-size, color, background-color,
        border-color;
    }
  }
  .animated-underline:hover,
  .animated-underline:focus-visible {
    background-size: 0 2px, 100% 2px;
  }

  .animated-blue-underline {
    background-image: linear-gradient(#33333300, #33333300),
      linear-gradient(to right, #3b82f6, #2563eb);
    background-size: 100% 2px, 0 2px;
    background-position: 100% 100%, 0 100%;
    background-repeat: no-repeat;
  }
  @media (prefers-reduced-motion: no-preference) {
    .animated-blue-underline {
      transition: 0.3s ease;
      transition-property: background-size, color, background-color,
        border-color;
    }
  }
  .animated-blue-underline:hover,
  .animated-blue-underline:focus-visible {
    background-size: 0 2px, 100% 2px;
  }
}

.twitter-tweet-rendered {
  margin: 0 !important;
}

.twitter-tweet > iframe {
  border-radius: 12px;
  @apply shadow-lg dark:shadow-stone-900;
}

.particle-container {
  height: 100%;
}
.particle-container > canvas {
  height: 100% !important;
  position: absolute !important;
}

.draggedover {
  background-color: rgba(255, 255, 255, 0.7);
}
.draggedover p,
.draggedover i {
  opacity: 1;
}

.image-container {
  height: 80vh;
}

.image-container img {
  @apply bg-transparent;
}

.input-toggle:checked ~ .dot {
  transform: translateX(100%);
  @apply border-white bg-primary-500;
}

.input-toggle:checked ~ .checkbox-bg {
  @apply border border-primary-600 bg-white;
}

.ais-Pagination {
  @apply w-full;
}
.ais-Pagination-list {
  @apply flex justify-center space-x-4;
}

.ais-Pagination-item--selected {
  @apply text-primary-500;
}

.PhoneInputInput {
  border: none;
  padding: 0;
}

.multi-select__input:focus,
.PhoneInputInput:focus {
  box-shadow: initial;
  border-color: initial;
}

.basic-multi-select input {
  outline: none !important;
  border: 0px transparent !important;
  box-shadow: none !important;
}

/* Date Picker */

.react-datepicker__input-container input {
  width: inherit;
}

.react-datepicker__day--keyboard-selected {
  background-color: theme('colors.primary.200') !important;
}

.react-datepicker__day--selected {
  background-color: theme('colors.primary.500') !important;
}

.react-datepicker__input-container input {
  border-color: theme('colors.inkanLightGray');
  border-radius: 5px;
}

.react-datepicker__input-container input::placeholder {
  color: theme('colors.stone.400') !important;
}

.react-datepicker__triangle {
  position: relative;
  left: -32px !important;
}

.react-datepicker__input-container .invalid {
  border-color: theme('colors.red.500');
  border-radius: 5px;
}

.react-datepicker__input-container input:focus {
  border: 1px solid theme('colors.inkanText');
  box-shadow: none !important;
}

/* Drag'n'Drop related */
.dnd-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.dnd-count {
  position: absolute;
  top: -10px;
  right: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #2389ff;
  font-size: 0.8rem;
  font-weight: 600;
  color: #fff;
}

.dnd-sortable-tree_folder_wrapper {
  list-style: none;
  box-sizing: border-box;
  margin-bottom: -1px;
  position: relative;
}

.dnd-sortable-tree_folder_tree-item {
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.dnd-sortable-tree_folder_clone {
  display: inline-block;
  pointer-events: none;
}

.dnd-sortable-tree_folder_clone > .dnd-sortable-tree_folder_tree-item {
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 24px;
  border-radius: 4px;
}

.dnd-sortable-tree_folder_ghost {
  opacity: 0.5;
}

.dnd-sortable-tree_folder_disable-selection {
  user-select: none;
  -webkit-user-select: none;
}

.dnd-sortable-tree_folder_disable-interaction {
  pointer-events: none;
}

.dnd-sortable-tree_folder_line {
  width: 20px;
  align-self: stretch;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><line stroke='black' style='stroke-width: 1px;' x1='50%' y1='0' x2='50%' y2='100%'/></svg>");
}

.dnd-sortable-tree_folder_line-last {
  width: 20px;
  align-self: stretch;
}

.dnd-sortable-tree_folder_line-to_self {
  width: 20px;
  align-self: stretch;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><line stroke='black' style='stroke-width: 1px;' x1='50%' y1='0' x2='50%' y2='100%'/><line stroke='black' style='stroke-width: 1px;' x1='50%' y1='50%' x2='100%' y2='50%'/></svg>");
}

.dnd-sortable-tree_folder_line-to_self-last {
  width: 20px;
  align-self: stretch;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><line stroke='black' style='stroke-width: 1px;' x1='50%' y1='0' x2='50%' y2='50%'/><line stroke='black' style='stroke-width: 1px;' x1='50%' y1='50%' x2='100%' y2='50%'/></svg>");
}

.dnd-sortable-tree_folder_tree-item-collapse_button {
  border: 0;
  width: 20px;
  align-self: stretch;
  transition: transform 250ms ease;
  background: url("data:image/svg+xml;utf8,<svg width='10' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 70 41'><path d='M30.76 39.2402C31.885 40.3638 33.41 40.995 35 40.995C36.59 40.995 38.115 40.3638 39.24 39.2402L68.24 10.2402C69.2998 9.10284 69.8768 7.59846 69.8494 6.04406C69.822 4.48965 69.1923 3.00657 68.093 1.90726C66.9937 0.807959 65.5106 0.178263 63.9562 0.150837C62.4018 0.123411 60.8974 0.700397 59.76 1.76024L35 26.5102L10.24 1.76024C9.10259 0.700397 7.59822 0.123411 6.04381 0.150837C4.4894 0.178263 3.00632 0.807959 1.90702 1.90726C0.807714 3.00657 0.178019 4.48965 0.150593 6.04406C0.123167 7.59846 0.700153 9.10284 1.75999 10.2402L30.76 39.2402Z' /></svg>")
    no-repeat center;
}

.dnd-sortable-tree_folder_tree-item-collapse_button-collapsed {
  transform: rotate(-90deg);
}
.dnd-sortable-tree_folder_handle {
  width: 20px;
  align-self: stretch;
  cursor: pointer;
  background: url("data:image/svg+xml;utf8,<svg  xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' width='12'><path d='M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z'></path></svg>")
    no-repeat center;
}
.MuiDataGrid-columnHeaders {
  background: theme('colors.inkanTableHeader');
}

.MuiDataGrid-columnHeaderTitle {
  font-size: theme('fontSize.xs') !important;
}

.MuiDataGrid-row--borderBottom {
  background: theme('colors.inkanTableHeader') !important;
}

.MuiDataGrid-root {
  border-radius: 0px !important;
}

.MuiDataGrid-columnHeader:focus-within,
.MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.MuiDataGrid-row.default .MuiDataGrid-cell {
  color: theme('colors.stone.400');
}

.MuiDataGrid-row.custom-email-task {
  background: theme('colors.inkanTableRow');
}

.MuiDataGrid-row.default {
  pointer-events: none;
}

.MuiDataGrid-row.read-only {
  opacity: 0.5;
}

.first-col {
  padding-left: theme('spacing.6') !important;
}

.first-col.icon-col {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.single-select__placeholder {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* SCROLLBAR STYLES */

* {
  scrollbar-color: rgba(0, 0, 0, 0.4) transparent;
}

.calendar-input input::placeholder {
  color: theme('colors.gray.300');
}

.MuiDataGrid-columnHeaderCheckbox {
  margin: 0px 1px !important;
}

.PrivateSwitchBase-input {
  left: initial !important;
  top: initial !important;
}

.MuiCheckbox-root.Mui-disabled {
  visibility: hidden;
}
